.divTableHighLevelStats{
  display: table;
  width: 100%;
}
.divTableHighLevelStatsRow {
  display: table-row;
}
.divTableHighLevelStatsHeading {
  background-color: #EEE;
  display: table-header-group;
}
.divTableHighLevelStatsCell, .divTableHighLevelStatsHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
}
.divTableHighLevelStatsHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}
.divTableHighLevelStatsFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableHighLevelStatsBody {
  display: table-row-group;
}
